<template>
  <v-col cols="12">
    <v-row
      v-if="isCatalogsPage"
    >
      <v-col
        class="d-flex justify-start"
      >
        <v-btn
          class="mr-4"
          height="56px"
          x-large
          disabled
          @click="showForm"
        >
          <v-icon>mdi-plus</v-icon>Добавить запись
        </v-btn>
    
        <v-text-field
          v-model="searchValue"
          v-debounced
          hide-details="auto"
          background-color="#EDEDED"
          class="ba-0 rounded-l rounded-r"
          filled
          rounded
          full-width
          placeholder="Поиск"
          clearable
          @click:clear="() => (searchValue = '')"
        />
      </v-col>
    </v-row>
    <v-text-field
      v-else
      v-model="searchValue"
      v-debounced
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-l rounded-r"
      filled
      rounded
      full-width
      placeholder="Поиск"
      clearable
      @click:clear="() => (searchValue = '')"
    />

    <v-simple-table class="mt-2">
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="`header-${header.value}`"
          >
            {{ header.name }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in filtered"
          :key="'type-item-' + idx"
        >
          <td
            v-for="header in headers"
            :key="`type-item-${idx}-${header.value}`"
          >
            <text-highlight
              :queries="searchValue || ''"
            >
              {{
                item[header.value]
              }}
            </text-highlight>
          </td>
          <td>
            <div class="d-flex justify-end">
              <v-btn
                width="36px"
                min-width="36px"
                class="mr-4"
                :disabled="!canEdit(item)"
                @click="edit(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                width="36px"
                min-width="36px"
                color="red"
                :disabled="!canDelete(item)"
                @click="remove(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    
  data:() => ({
    searchValue:"",
    noResidents:[],
    headers:[
      {
        name:'Псевдоним',
        value:'own_name'
      },
      {
        name:'ИНН',
        value:'inn'
      },      
      {
        name:'Имя',
        value:'name'
      },
      {
        name:'Улица',
        value:'street'
      },
      {
        name:'Регион',
        value:'region'
      },
      {
        name:'Индекc',
        value:'postal_index'
      },

      {
        name:'Код страны',
        value:'country_letter'
      },
      {
        name:'Название страны',
        value:'country_name'
      },
    ]
  }),
  computed:{
    ...mapGetters({
      user: 'auth/getUser'
    }),
    userId(){
      return this.user?.user?.id
    },
    isCatalogsPage(){
      return this.$route.name === 'CatalogsHomepage'
    },
    filtered(){
      let filtered = this.noResidents
      if (this.searchValue !== null && this.searchValue.length) {
        filtered = filtered.filter(
          (i) => i.search.includes(this.searchValue.toLowerCase())
        );
      }
      return filtered
    }
  },
  created() {
    this.loadNoResidents()
  },
  methods:{
    canEdit(item){
      return item.user_id === null ? true :(item.user_id === this.userId ? true : this.$can("cat.div.update"))
    },
    canDelete(item){
      return item.user_id === null ? true :(item.user_id === this.userId ? true : this.$can("cat.div.update"))
    },
    loadNoResidents(){
      this.$store.dispatch("division/loadNoResidents").then(res => {
        this.noResidents = res.data.map(item => {
          const {own_name, unp, name} = item
          return {
            ...item,
            search:[own_name, unp, name].join('').toLowerCase()
          }
        })
      }).catch(() => {
        this.$snackbar({text:'Ошибка загрузки', top:false, right: false, color:'red'})
      })
    },
    showForm(){
      this.$emit('perform', {component:'noResidentsForm'})
    },
    edit(item){
      if(this.isCatalogsPage){
        this.$emit('perform', {component:'noResidentsForm', item})
      }
    },
    remove(item){
      this.$store.dispatch("division/deleteNoResident", item.id).then(() => {
        this.$snackbar({text:'Запись удалена', top:false, right: false, })
        this.loadNoResidents()
      }).catch(()=> {
        this.$snackbar({text:'Ошибка', top:false, right: false, color:'red'})
      })
    }
  }
}
</script>